import React, { useEffect } from "react"
import { graphql, navigate } from 'gatsby';
import Layout from "../components/layout"
import CatoImg from "../components/cato-image"
import ContentBreadCrumbs from "../components/bread-crumbs"
import Marketo from "../components/marketo"
import CatoButton from "../components/cato-button"
import CatoLink from "../components/cato-link"

import useDeviceDetect from "../hooks/useDeviceDetect"

import {
  LANG,
  EXPERT_LEVEL_2,
  HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT,
} from "../common/consts"
import FooterBadge from "../assets/images/sase-expert/SASE EXPERT BADGE_ shadow.png"
import Level2Badge3D from "../assets/images/sase-expert/level-2/SASE Expert_Level2_3D.svg"
import { ReactComponent as FB } from "../assets/images/sase-expert/svg/ICONS/SVG/FACEBOOK.svg"
import { ReactComponent as LinkedIn } from "../assets/images/sase-expert/svg/ICONS/SVG/LINKEDIN.svg"
import { ReactComponent as Twitter } from "../assets/images/sase-expert/svg/ICONS/SVG/TWITTER.svg"
import Logo from "../assets/images/CATO LOGO 2019-svg.svg"
import { ReactComponent as Arrow } from "../assets/images/sase-expert/level-2/Arrow.svg"
import "../assets/styles/pages/expert-page.scss"
import "../assets/styles/pages/expert-page-2.scss"
import "../components/icon-bold-text-inline/style.scss"
// import US from "../assets/images/sase-expert/US.svg"
// import DU from "../assets/images/sase-expert/DU.svg"
// import FR from "../assets/images/sase-expert/FR.svg"
// import IT from "../assets/images/sase-expert/IT.svg"
// import GE from "../assets/images/sase-expert/GR.svg"
// import JP from "../assets/images/sase-expert/JAP.svg"

// const EXPERT_FORM_IDS = {
//   [EXPERT_LANGUAGE_CODES.english]: EXPERT_PAGE_FORM_ID,
//   [EXPERT_LANGUAGE_CODES.italian]: EXPERT_ITALIAN,
//   [EXPERT_LANGUAGE_CODES.german]: EXPERT_GERMAN,
//   [EXPERT_LANGUAGE_CODES.french]: EXPERT_FRENCH,
//   [EXPERT_LANGUAGE_CODES.dutch]: EXPERT_DUTCH,
//   [EXPERT_LANGUAGE_CODES.japanese]: EXPERT_JAPANESE,
// }

// const FLAGS = [
//   {
//     img: US,
//     code: EXPERT_LANGUAGE_CODES.english,
//     link: "/sase/sase-expert-level-1/",
//   },
//   {
//     img: DU,
//     code: EXPERT_LANGUAGE_CODES.dutch,
//     link: "/sase-expert-level-1-nl/",
//   },
//   {
//     img: IT,
//     code: EXPERT_LANGUAGE_CODES.italian,
//     link: "/sase-expert-level-1-it/",
//   },
//   {
//     img: FR,
//     code: EXPERT_LANGUAGE_CODES.french,
//     link: "/sase-expert-level-1-fr/",
//   },
//   {
//     img: GE,
//     code: EXPERT_LANGUAGE_CODES.german,
//     link: "/sase-expert-level-1-de/",
//   },
// ]

// const LanguagePicker = ({ items, label, lang }) => (
//   <div className="language-picker">
//     <span>{label}</span>
//     <ul>
//       {items.map((flag, i) => (
//         <li
//           key={`expert-flag-link-${i}`}
//           className={flag.code === lang && "active"}
//         >
//           <a href={flag.link}>
//             <CatoImg img={flag.img} />
//           </a>
//         </li>
//       ))}
//     </ul>
//   </div>
// )

const ExpertPageLevel2 = ({ data, pageContext, location }) => {
  const { wpPage } = data;
  const { expertPageLevelTwo: {
      greenBoxText, greenBoxButtonLabel, greenBoxButtonLink, footerSubheading, contentsParagraph,
      expertHeadline, expertSubheading, expertThankYouPage, expertOpeningText, expertVideoCtaLabel,
      expertLevelText, expertContents, expertCourseInfo, expertFooterHeadline, expertFooterTagline, level1Link
  }} = wpPage;

  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    const applyButtons = Array.from(
      document.querySelectorAll(".cato-button.apply")
    )

    if (applyButtons.length) {
      applyButtons.forEach(el => (el.onclick = scrollDown))
    }
  }, [])

  //   const footerHeading = expertPage.expertFooterHeadline
  //     ? expertPage.expertFooterHeadline
  //     : null

  const scrollDown = e => {
    e.preventDefault()
    const form = document.querySelector("footer")
    const top =
      form.getBoundingClientRect().top -
      HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT -
      100
    const scrollTop = window.scrollY

    window.scrollTo(0, top + scrollTop)
  }

  const onFormSuccess = () => {
    const redirect = expertThankYouPage
        ? expertThankYouPage
        : "/sase-expert-thank-you"

    navigate(redirect, {
        replace: false,
    })
  }

    const levels = <div className="levels">
        <ul>
            <li className="dark">
                <CatoLink path={level1Link}>{expertLevelText} 1</CatoLink>
            </li>
            <li className="active">{expertLevelText} 2</li>
        </ul>
    </div>

  return (
    <Layout
        className={"expert-page level-2"}
        noFooter={true}
        yoastData={data.wpPage.seo}
        seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
        seoPath={location.pathname}
        title={data.wpPage.title}
        lang={pageContext.lang}
        // translations={translations}
        wpid={wpPage.databaseId}
    >
      <section className="top">
        {!isMobile && (
          <ContentBreadCrumbs
            links={[
              {
                path: "/sase/",
                title: "Secure Access Service Edge (SASE)",
              },
              {
                path: location.pathname,
                title: "SASE Expert Certification",
              },
            ]}
          />
        )}
        <div className="content container">
          {/* {isMobile && (
            <LanguagePicker
              items={FLAGS}
              label={"Change Language"}
              lang={EXPERT_LANGUAGE_CODES.english}
            />
          )} */}
          {isMobile && levels}
            {expertHeadline && <h1>{expertHeadline}</h1>}
          <div className="left">
            {!isMobile && levels}

            <div className="apply-content">
                {expertSubheading && <h2>{expertSubheading}</h2>}
                {expertOpeningText && <div dangerouslySetInnerHTML={{__html: expertOpeningText}}/>}

              <div className="pre-register">
                <CatoButton
                  className="orange br-4 mid apply pre"
                  label={expertVideoCtaLabel ? expertVideoCtaLabel : 'Pre-Register Now'}
                />
              </div>
                {greenBoxText &&
                <div className="mid-banner">
                    <h3>{greenBoxText}</h3>
                    <CatoButton
                        path={greenBoxButtonLink}
                        className={"br-8 mid white-apply"}
                        label={greenBoxButtonLabel ? greenBoxButtonLabel : 'Apply Now'}
                    />
                </div>
                }
            </div>
          </div>
          <div className="right">
            {/* {!isMobile && (
              <LanguagePicker
                items={FLAGS}
                label={"Change Language"}
                lang={EXPERT_LANGUAGE_CODES.english}
              />
            )} */}
            <div className="contents">
                {expertContents.heading && <h2 className="green">{expertContents.heading}</h2>}
                {contentsParagraph && <p>{contentsParagraph}</p>}
                {expertContents.listItems?.length &&
                <ul className="scroll-text">
                    {/* {expertPage.expertContents &&
                  expertPage.expertContents.listItems &&
                  expertPage.expertContents.listItems.length &&
                  expertPage.expertContents.listItems.map((item, i) => (
                    <li key={`list-item-${i}`}>{item.item}</li>
                  ))} */}
                    {expertContents.listItems.map((item, i) => <li key={`list-item-${i}`}>{item.item}</li>)}
                </ul>
                }
            </div>
            <div className="course-info">
                {expertCourseInfo.heading && <h2 className="green">
                    {expertCourseInfo.heading}
              </h2>}
                {expertCourseInfo.infoRow?.length &&
                <table>
                    <tbody>
                    {expertCourseInfo.infoRow.map((row, i) => (
                        <tr key={`course-info-row-${i}`}>
                            <td className="bold">{row.label}</td>
                            <td>{row.value}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                }
            </div>
            {isMobile && (
              <CatoButton
                className="green-bg br-4 mid apply"
                label="Apply Now"
              />
            )}
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div>
            <div className="image-wrapper">
              <CatoImg img={Level2Badge3D} />
            </div>
            {!isMobile && expertFooterHeadline && <h2>{expertFooterHeadline}</h2>}
            {!isMobile && footerSubheading && (
              <span style={{ color: "#fff" }}>
                  {footerSubheading}
              </span>
            )}
            <div className="form-wrapper">
              <Arrow />
              {isMobile && expertFooterHeadline && <h2>{expertFooterHeadline}</h2>}
              {isMobile && footerSubheading && (
                <span>
                    {footerSubheading}
                </span>
              )}
              <Marketo
                additionalClass={"expert-page"}
                formId={EXPERT_LEVEL_2}
                onFormSuccess={onFormSuccess}
              />
            </div>
            <CatoImg className={"logo"} img={Logo} />
              {expertFooterTagline && <h2>{expertFooterTagline}</h2>}
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/CatoNetworks/">
                  <FB />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/cato-networks/">
                  <LinkedIn />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/catonetworks">
                  <Twitter />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </Layout>
  )
}

export default ExpertPageLevel2;

export const query = graphql`
  query expertPageLevelTwoQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastData
      title
      content
      slug
      id
      databaseId
      date(formatString: "MMMM DD, YYYY")
      path: uri
      pageSeoTitle {
        pageSeoTitle
      }
      expertPageLevelTwo {
        expertHeadline
        expertSubheading
        expertOpeningText
        expertThankYouPage
        expertVideoCtaLabel
        expertLevelText
        expertContents {
        heading
        listItems {
              item
            }
        }
        expertCourseInfo {
        heading
        infoRow {
              label
              value
            }
        }
        expertFooterHeadline
        expertFooterTagline
        contentsParagraph
        greenBoxText
        greenBoxButtonLabel
        greenBoxButtonLink
        footerSubheading
        level1Link
      }
      ...StickyBanner
      ...VideoSection
      ...ResourcesTrio
      ...GartnerStrip
    }
  }
`

